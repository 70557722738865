.hts-matches {
	display: flex;
	justify-content: center;
	width: 100%;
	margin-top: 25px;
}

.matches-data {
	display: flex;
	justify-content: space-around;
	width: 100%;
}

.data-teamblock {
	width: 150px;
	display: block;
	text-align: center;
}

.teamblock__image {
	height: 45px;
}

.teamblock__teamname,
.data-score {
	font-family: "NEXA X BOLD";
	text-transform: uppercase;
	font-size: 12px;
	color: var(--main-color);
}

.data-score {
	line-height: 90px;
	display: flex;
	justify-content: space-between;
	width: 50px;
}

.hide {
	display: none;
}

.colorblue {
	color: blue;
}

.colorred {
	color: red;
}

.data-scores {
	display: none;
	width: fit-content;
	line-height: 90px;
	font-size: 12px;
	text-transform: uppercase;
	color: red;
	font-family: "NEXA X BOLD";
}

.Postponed {
	display: block;
}

@media only screen and (max-width: 767px) {
	.teamblock__image {
		height: 35px;
	}

	.data-teamblock {
		width: 100px;
	}

	.teamblock__teamname,
	.data-score,
	.data-scores {
		font-size: 11px;
	}
}