.loader_container {
    margin: 20px auto;
    border: 5px solid #f3f3f3;
    border-top: 5px solid var(--main-color);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.error_message_container {
    color: #ee0000;
    margin: 50px auto;
    text-align: center;
}