.filterable-container {
    margin-bottom: 40px;
    width: fit-content;
}

.filterable-container__matchwrappers {
    display: inline-block;
    width: 30px;
    height: 30px;
    background: linear-gradient(180deg, white, #c8c9cb);
    border-radius: 100%;
    text-align: center;
    margin: 6px 6px;
    cursor: pointer;
}

.activatebtn {
    width: 40px;
    height: 40px;
    margin: 0px 1px;
    border: 6px solid #11ad60;
}

.filterable-container__matchnumbers {
    color: var(--light-color);
    font-family: 'NEXA X BOLD';
    line-height: 28px;
}

.matchesvisible {
    width: 70%;
    display: inline-block;
}

.matchdate-formated {
    margin-top: 10px;
    padding: 5px 0;
    color: white;
    font-family: 'NEXA BOLD';
    font-size: 10px;
    width: fit-content;
    border-bottom: 1px solid white;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.pagenovisible {
    display: none;
}

.betSlip_promo_section {
    margin-right: 20px;
    width: 30%;
    z-index: 100;
}

.promo_banner {
    color: white;
    margin-top: 20px;
    width: 100%;
}

/* .promo_banner_landscape {
    color: white;
    max-height: 250px;
    max-width: 100%;
} */

.game__inactivePools-titleWrapper {
    margin: 20px 0 10px 0;
    background-color: #11ad60;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
}

.game__inactivePools-title {
    color: white;
    font-size: 16px;
    text-transform: uppercase;
    font-family: "NEXA X BOLD";
    letter-spacing: 1px;
    line-height: 30px;
}

.game__inactivePools-matchcount {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: var(--btn-color);
    display: inline-block;
    text-align: center;
    margin-left: 10px;
    line-height: 30px;
}

.game__inactivePools-count {
	font-family: "NEXA X BOLD";
	color: var(--main-color);
	font-size: 14px;
}

@media only screen and (max-width: 991px) {
    .betSlip_promo_section {
        width: 0;
    }

    .matchesvisible {
        width: 100%;
    }
}