.myBets-container {
    font-family: 'NEXA X BOLD';
    border-radius: 10px;
}

.myBets-navMenu {
    display: flex;
    justify-content: space-around;
    margin: 0 0 3px 0;
    padding: 0;
    background-color: var(--white-color);
    text-transform: uppercase;
    color: var(--main-color);
    list-style: none;
    font-family: 'NEXA X BOLD';
    border-radius: 15px;
}

.myBets-navMenuItem {
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    text-align: center;
    cursor: pointer;
}

.myBets-navMenuItem__active,
.myBets-navMenuItem:hover,
.myBets-navMenuItem:active,
.myBets-navMenuItem:focus,
.myBets-navMenuItem:target {
    background-color: var(--btn-color);
    border-bottom: 2px;
    border-color: var(--main-color);
    text-decoration: underline;
    text-underline-offset: 8px;
    text-decoration-thickness: 3px;
}

.myBets-navMenuItem:first-child {
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
}

.myBets-navMenuItem:last-child {
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
}

.myBets__betDate {
    color: var(--white-color);
    background-color: var(--secbtn-color);
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    padding: 20px;
    margin-bottom: 3px;
    border-radius: 15px;
}

@media (max-width: 767px) {

    .myBets-container,
    .myBets-content__title-btn {
        font-size: 12px;
    }

    .myBets-content__title-btn {
        padding: 10px 20px;
    }
}