.betslip {
	background: var(--light-color);
	width: 100%;
	border-radius: 10px;
	z-index: 100;
}

.betslip__hdr-wrapper {
	background-color: #11ad60;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	display: flex;
	justify-content: space-between;
	padding: 20px;
}

.betslip__bdy-wrapper {
	min-height: 100px;
}

.bdy-wrapper-searchlabel {
	display: block;
	color: white;
	width: fit-content;
	margin: 15px auto 0;
	font-size: 14px;
}

.bdy-wrapper__searchfield {
	border: none;
	height: 30px;
	border-radius: 5px 0px 0px 5px;
}

.bdy-wrapper-searchbtn {
	width: 40px;
	padding: 0;
	margin: 0;
	cursor: pointer;
	border-radius: 0 5px 5px 0;
}

.bdy-wrapper__searchMsg {
	text-align: center;
	color: #ee0000;
	text-shadow: 1px 1px 1px #222222;
	font-size: 14px;
}

.bdy-wrapper__searchform {
	display: flex;
	border-radius: 5px;
	background: linear-gradient(180deg, white, #c8c9cb);
	height: 30px;
	width: fit-content;
	margin: 10px auto;
}

.hdr-wrapper-betsliptitle {
	color: white;
	font-size: 16px;
	text-transform: uppercase;
	font-family: "NEXA X BOLD";
	letter-spacing: 1px;
}

.betsliptitle-matchcount {
	width: 20px;
	height: 20px;
	border-radius: 100%;
	background-color: var(--btn-color);
	display: inline-block;
	text-align: center;
	margin-left: 5px;
	line-height: 20px;
}

.matchcount__count {
	font-family: "NEXA X BOLD";
	color: var(--main-color);
	font-size: 14px;
}

.hdr-wrapper__betslipclear {
	color: white;
	cursor: pointer;
	font-size: 14px;
}

.hdr-wrapper__containeract {
	display: none;
	cursor: pointer;
}

.bdy-wrapper-blc {
	font-family: "NEXA X BOLD";
	width: fit-content;
	background-color: #11ad60;
	text-transform: uppercase;
	font-size: 12px;
	padding: 10px;
	border-radius: 20px;
	margin: 15px auto;
}

.bdy-wrapper-pools {
	margin: 15px auto;
	width: fit-content;
	position: relative;
}

.blc__balance {
	color: #0c2639;
}

.pools__totallabel {
	color: white;
}

.blc__balance {
	margin-right: 5px;
}

.blc__amount,
.pricewrapper__totalpool,
.details-wrapper__info {
	color: white;
}

.btncontainer__warning-message {
	font-family: "NEXA BOLD";
	text-align: center;
	margin: 0 auto 10px auto;
	color: #ee0000;
	font-size: 12px;
}

.pools__totallabel,
.pools-pricewrapper {
	display: inline-block;
}

.pools__minimumbet {
	margin-top: 10px;
	display: block;
	color: var(--btn-color);
	font-family: "NEXA BOLD";
	font-size: 11px;
	text-align: center;
	text-transform: uppercase;
}

.pools__totallabel,
.pricewrapper__totalpool,
.details-wrapper__info {
	font-family: "NEXA X BOLD";
	text-transform: uppercase;
	font-size: 12px;
}

.pools-pricewrapper {
	margin-left: 10px;
	background-color: #11ad60;
	font-size: 12px;
	padding: 10px 15px;
	border-radius: 20px;
}

.bdy-wrapper-details {
	display: flex;
	padding: 10px 20px 5px 20px;
}

.details-wrapper {
	display: block;
	width: 40%;
	padding: 10px 0;
}

.details-wrapper__info {
	display: block;
	padding: 5px;
	word-break: break-word;
}

.bdy-wrapper-btncontainer {
	margin: 0 auto;
	width: 60%;
	display: flex;
	justify-items: center;
	align-items: center;
}

.btncontainer__btn {
	margin: 7px auto 30px auto;
	width: 100%;
	padding: 10px 0;
	border-radius: 50px;
	border: none;
	font-size: 12px;
	background-color: var(--btn-color);
	text-transform: uppercase;
	font-family: "NEXA X BOLD";
	color: var(--light-color);
	cursor: pointer;
}

.savebet-btn {
	margin-left: 10px;
}

.betslip-action {
	display: none;
	background-color: var(--light-color);
	border-radius: 10px;
	text-align: center;
	width: fit-content;
	padding: 10px;
	position: fixed;
	bottom: 100px;
	right: 25px;
	z-index: 1000;
}

.action-matchcount {
	margin: auto;
	border-radius: 100%;
	width: 30px;
	height: 30px;
	background-color: var(--btn-color);
}

.matchcount__nmbr {
	color: var(--main-color);
	font-family: "NEXA X BOLD";
	line-height: 30px;
}

.wrapper__lbl {
	margin-top: 5px;
	text-transform: uppercase;
	color: white;
	font-family: "NEXA BOLD";
	font-size: 14px;
	letter-spacing: 1px;
}

.bdy-wrapper-hrzline {
	position: relative;
}

.hrzline__line {
	content: "";
	position: absolute;
	border-top: 3px solid var(--main-color);
	left: 0;
	right: 0;
}

.popup-container {
	position: fixed;
	width: 300px;
	top: 30%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
	border-radius: 10px;
	padding: 20px 40px;
	z-index: 1000;
	display: none;
}

.popupvisible {
	display: block;
}

.popup-container-titlewrapperpopup {
	text-align: center;
	margin: auto;
}

.titlewrapperpopup__title {
	text-transform: uppercase;
	color: var(--main-color);
	font-family: "NEXA X BOLD";
	font-size: 22px;
}

.popup-container-data {
	word-break: break-word;
	text-align: center;
}

.popup-container-data__betdata {
	color: var(--main-color);
	font-family: "NEXA BOLD";
	font-size: 14px;
}

.popup-container-actions {
	display: flex;
	justify-content: space-evenly;
	margin-top: 10px;
}

.popup-container-actions__btnaction {
	margin-right: 20px;
	border-radius: 15px;
	padding: 5px 10px;
	border: none;
	background-color: #24352d;
	color: white;
	font-family: "NEXA BOLD";
	font-size: 12px;
	text-transform: uppercase;
	cursor: pointer;
}

.popup-container-actions__btnaction:last-of-type {
	margin-right: 0;
}

.placebetbtn {
	background-color: var(--btn-color);
	color: var(--main-color);
	text-decoration: none;
}

.popup-container-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 96, 53, 0.7);
	z-index: 100;
	display: block;
}

.popup-container-overlay-none {
	display: none;
}

.popup-container-message {
	text-align: center;
	font-family: "NEXA BOLD";
	font-size: 12px;
	text-transform: uppercase;
	color: #11ad60;
	margin-top: 35px;
}

.popup-container-message-error {
	color: #ee0000;
}

.hide-stakepools {
	display: none;
}

@media only screen and (max-width: 991px) {
	.betslip {
		width: 90%;
		position: fixed;
		bottom: 0;
		right: 0;
		left: 0;
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 0px;
		background: rgba(0, 96, 53, 0.7);
		margin: auto;
	}

	.openbetslip {
		height: 0;
		overflow: hidden;
		transition: all ease-out 0.5s;
	}

	.hdr-wrapper__containeract {
		display: block;
	}

	.hdr-wrapper__containeract:after {
		content: "";
		border-right: 2px solid white;
		border-bottom: 2px solid white;
		position: absolute;
		margin: 5px;
		width: 8px;
		height: 8px;
		top: 5px;
		left: 50%;
		transform: translate(-50%, -50%) rotate(43deg);
	}


	.btncontainer__btn {
		margin-bottom: 15px;
		padding: 9px 0;
		color: var(--main-color);
	}

	.bdy-wrapper-pools,
	.bdy-wrapper-blc {
		margin: 10px auto;
	}

	.pools-pricewrapper {
		padding: 6px 13px;
		line-height: 12px;
	}

	.betslip__hdr-wrapper {
		padding: 12px;
	}

	.pools__totallabel,
	.pricewrapper__totalpool,
	.details-wrapper__info {
		font-size: 10px;
	}

	.hdr-wrapper-betsliptitle {
		font-size: 14px;
	}

	.betslip-action {
		display: block;
	}

	.hidecontainer {
		display: none;
	}

	.details-wrapper {
		width: 40%;
	}

	.bdy-wrapper-details {
		padding-left: 20%;
	}

	.hrzline__line {
		border-top: 3px solid white;
	}
}

@media only screen and (max-width: 1200px) {
	.bdy-wrapper-details {
		padding: 5px;
	}

	.details-wrapper__info {
		font-size: 11px;
	}
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}