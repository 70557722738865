.containerSlider {
    position: relative;
    max-width: 651px;
    height: 140px;
    margin: auto;
}

.promo_banner_landscape {
    object-fit: contain;
    max-width: 100%;
    height: 140px;
    margin: auto;
}