.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

:root {
	--white-color: #ffffff;
	--black-color: #000000;
	--main-color: #006035;
	--light-color: #398462;
	--btn-color: #ffca27;
	--secbtn-color: #24352d;
}

@font-face {
	font-family: 'NEXA BOLD';
	font-style: normal;
	src: url('../src/assets/fonts/nexaBold.2b70814885113f24656a.otf');
}

@font-face {
	font-family: "NEXA X BOLD";
	font-style: normal;
	src: url("../src/assets/fonts/nexaXBold.f56b289161bfd3264d00.otf");
}

.header-open {
	position: relative;
}

.header-open:after {
	content: "";
	position: fixed;
	background: rgba(0, 0, 0, 0.85);
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
}

.game-container {
	background-color: var(--main-color);
	padding: 50px 100px;
}

.game-container__wrapper {
	display: flex;
	justify-content: center;
}

.game-container__wrapper-pools {
	background-color: var(--light-color);
	box-sizing: border-box;
	width: 70%;
	padding: 15px 25px;
	border-radius: 15px;
}

.content-container {
	width: 100%;
	box-sizing: border-box;
	background-color: var(--light-color);
	padding: 50px 25px;
	border-radius: 15px;
	font-family: 'NEXA BOLD';
}

@media only screen and (max-width: 991px) {

	.content-container {
		padding: 20px 25px;
		background: none;
	}

	.game-container__wrapper-pools {
		width: 100%;
		/* background: none; */
		padding: 25px 25px;
	}

}

@media only screen and (max-width: 767px) {

	/* .game-container__wrapper-pools {
		width: 100%;
		background-color: var(--main-color);
		margin: 0;
		border-radius: 0;
		padding: 50px 0;
	} */
	.content-container {
		padding: 20px 0;
		background: none;
	}

	#site_body .grid {
		overflow: initial;
	}

	.game-container {
		padding: 20px;
	}

}

@media only screen and (max-width: 320px) {

	.content-container {
		background-color: var(--main-color);
		padding: 50px 0px;
	}

	.game-container {
		padding: 10px;
	}
}