.betMoreDetails-container {
    color: var(--white-color);
    text-transform: uppercase;
    margin-bottom: 10px;
}

.betMoreDetails__team-header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 15px;
}

.betMoreDetails__team-section {
    width: 100%;
    text-align: center;
}

.betMoreDetails__team-icon {
    height: 40px;
}

.betMoreDetails__team-name {
    font-size: 10px;
    margin-top: 0;
}

.betMoreDetails__match-text {
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: var(--btn-color);
}

.betMoreDetails__match-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.betMoreDetails__btn_match-option {
    position: relative;
    flex: 1 0 calc(33.3% - 10px);
    min-height: 50px;
    box-sizing: border-box;
    margin: 5px;
    padding: 5px;
    color: var(--main-color);
    background: linear-gradient(180deg, var(--white-color), #c8c9cb);
    text-transform: uppercase;
    font-family: 'NEXA X BOLD';
    border: 0px;
    border-radius: 10px;
}

.betMoreDetails__btn_match-option--green {
    color: var(--white-color);
    background: #4BB543;
}

.betMoreDetails__btn_match-option--red {
    color: var(--white-color);
    background: #FC100D;
}

.betMoreDetails__btn_match-option--yellow {
    color: var(--white-color);
    background: #EED202;
}

.betMoreDetails__ball {
    position: absolute;
    left: 7%;
    vertical-align: middle;
}