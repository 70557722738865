.betDetails {
    font-family: 'NEXA X BOLD';
    padding: 30px;
    display: flex;
    justify-content: space-between;
    background-color: var(--white-color);
}

.betDetails-container {
    margin-bottom: 20px;
}

.betDetails__title {
    text-align: center;
    text-transform: uppercase;
    color: var(--main-color);
    background: linear-gradient(180deg, var(--white-color), #c8c9cb);
    padding: 15px;
    font-family: 'NEXA X BOLD';
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.betDetails__costSection {
    margin-top: 40px;
    text-transform: uppercase;
    width: 100%;
}

.betDetails__costTitle,
.betDetails__winningsTitle {
    color: var(--main-color);
    margin-bottom: 5px;
}

.betDetails__costValue,
.betDetails__winningsValue {
    font-size: 12px;
}

.betDetails__ticketInfo {
    text-align: center;
    padding-top: 20px;
    width: 100%;
}

.betDetails__ticketInfoTitle {
    display: inline-block;
    font-family: 'NEXA BOLD';
    text-transform: uppercase;
    color: var(--white-color);
    padding: 13px 30px;
    background-color: #5fc17d;
    border: 0px;
    border-radius: 25px;
    margin-bottom: 15px;
}

.betDetails__ticketInfoDetail {
    margin-bottom: 5px;
}

.betDetails__ticketInfoDetailTitle {
    color: var(--main-color);
}

.betDetails__ticketInfoDetailValue {
    text-transform: uppercase;
    color: #7a7a7a;
}

.betDetails__winningsSection {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
}

.betDetails__ticketStatus {
    text-transform: uppercase;
    color: var(--white-color);
    background-color: #dd2b20;
    padding: 10px 15px;
    border: 0px;
    border-radius: 5px;
    font-size: 12px;
    margin-bottom: 10px;
}

.betDetails__moreDetails-title {
    text-align: center;
    text-transform: uppercase;
    color: var(--white-color);
    background-color: var(--main-color);
    padding: 15px;
    font-family: 'NEXA BOLD';
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    cursor: pointer;
}

.betDetails__moreDetails-arrow--open {
    display: inline-block;
    margin: 3px 0 0 10px;
    border-right: 3px solid var(--white-color);
    border-bottom: 3px solid var(--white-color);
    width: 8px;
    height: 8px;
    transform: rotate(225deg);
}

.betDetails__moreDetails-arrow {
    display: inline-block;
    margin: 0 0 3px 10px;
    border-right: 3px solid var(--white-color);
    border-bottom: 3px solid var(--white-color);
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
}

.betDetails__moreDetails--hidden {
    box-sizing: border-box;
    height: 0;
    background-color: #29483a;
    overflow: hidden;
    border-radius: 15px;
}

.betDetails__moreDetails--visible {
    height: fit-content;
    padding: 20px;
    margin-top: 1px;
}

@media (max-width: 767px) {
    .betDetails {
        padding: 15px;
    }

    .betDetails__ticketInfoTitle {
        padding: 8px 15px;
    }

    .betDetails__ticketStatus {
        padding: 5px 10px;
    }

    .betDetails__ticketInfoDetailValue {
        font-size: 12px;
    }

    .betDetails__costSection {
        width: 80%;
    }

    .betDetails__winningsSection {
        width: 80%;
    }

    .betDetails__moreDetails-title {
        background-color: var(--light-color);
    }
}