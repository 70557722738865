.pastpools-container {
	width: 95%;
	border-radius: 10px;
	height: fit-content;
}

.pastpool-container-datefilter {
	display: flex;
	justify-content: space-between;
	width: 95%;
	margin: 10px auto;
}

.datefilter-wrapper {
	width: 48%;
}

.monthDisabled {
	opacity: 0.4;
}

.rdn,
.hideall__title {
	font-family: "NEXA BOLD";
	font-size: 14px;
	text-transform: uppercase;
}

.rdn-control {
	background: linear-gradient(180deg, white, #c8c9cb) !important;
	border-radius: 5px !important;
	outline: none !important;
	height: 50px;
	line-height: 30px;
}

.rdn-control-placeholder,
.rdn-drop-menu-option {
	color: var(--main-color) !important;
}

.rdn-control-arrow-icon {
	border-color: var(--main-color) transparent transparent !important;
	border-width: 6px 6px 0 !important;
	top: 20px !important;
}

.pastpools-container-filter {
	background-color: #29483a;
	border-radius: 10px;
	width: 95%;
	margin: 10px auto 30px auto;
	padding-top: 5px;
}

.pastpools-container-filter-textblock {
	text-align: center;
	margin-bottom: 5px;
}

.pastpools-container-filter-textblock__title {
	font-family: "NEXA X BOLD";
	color: white;
	text-transform: uppercase;
}

.filter-pools {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.pastpools-wrapper {
	width: 70px;
	height: 70px;
	background-color: #0ca257;
	border-radius: 100%;
	text-align: center;
	line-height: 70px;
	margin: 10px 10px;
	cursor: pointer;
}

.activate__pastpool__matches {
	background-color: var(--btn-color);
}

.wrapper__item {
	font-family: "NEXA X BOLD";
	color: white;
	font-size: 35px;
}

.activated {
	color: var(--main-color);
}

.pastpools-container-hideall {
	width: 95%;
	background-color: #29483a;
	height: 20px;
	border-radius: 50px;
	margin: 5px auto;
	padding: 10px 0;
	text-align: center;
}

.hideall__title {
	color: white;
}

.hideall-btnarrow {
	text-align: right;
	margin-right: 40px;
	margin-top: -10px;
}

.hideall-btnarrow:after {
	content: "";
	position: absolute;
	border-right: 2px solid white;
	border-bottom: 2px solid white;
	width: 8px;
	height: 8px;
	transform: rotate(225deg);
}

.openpast:after {
	transform: rotate(45deg);
}

@media only screen and (max-width: 767px) {
	.pastpools-container {
		width: 100%;
	}

	.hideall-btnarrow:after {
		margin-top: -4px;
		width: 6px;
		height: 6px;
	}

	.pastpools-wrapper {
		width: 40px;
		height: 40px;
		line-height: 40px;
	}

	.wrapper__item {
		font-size: 20px;
	}
}