.previous-container {
    border-radius: 50px;
    background: linear-gradient(180deg, white, #c8c9cb);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;
    height: 55px;
    padding: 5px;
    text-transform:none;
}

.dataprevious {
    width: 100%;
    text-align: center;
    color: var(--main-color);
	font-size: 14px;
	font-family: "NEXA BOLD";
}
.scorecontainer{ 
    width: 45px;
    padding: 5px 7px;
    margin: auto;
    border-radius: 5px;
    color: white;
}
.loosingteam {
    background-color: #f30a26;
}
.winningteam {
    background-color: #40c080;
}
.drawteams {
    background-color: #4f5c56;
}

@media only screen and (max-width: 991px) {
    .dataprevious {
        font-size: 14px;
    }   
}

@media only screen and (max-width: 767px) {
    .dataprevious {
        font-size: 12px;
    }   
}

