.matchList-container {
    position: relative;
    color: var(--white-color);
    text-transform: uppercase;
    display: flex;
}

.matchList__back-button {
    border-left: 3px solid var(--white-color);
    border-bottom: 3px solid var(--white-color);
    position: absolute;
    left: 28px;
    top: 25px;
    width: 15px;
    height: 15px;
    transform: rotate(45deg);
    cursor: pointer;
}

@media screen and (max-width: 991px) {
    .matchList__back-button {
        top: -15px;
    }
}

@media screen and (max-width: 767px) {
    .matchList__back-button {
        top: -15px;
        left: 3px;
    }
}

.matchList__start-date {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;

}

.savedbet {
    text-decoration: none !important;
}

.matchlist__buttonshowbet {
    background: linear-gradient(180deg, white, #c8c9cb);
    border-radius: 5px;
    color: var(--main-color);
    text-transform: capitalize;
    font-size: 12px;
    cursor: pointer;
    padding: 5px;
}

.matchlist__buttoncopy {
    background: linear-gradient(180deg, white, #c8c9cb);
    border-radius: 5px;
    color: var(--main-color);
    text-transform: capitalize;
    font-size: 12px;
    cursor: pointer;
    border: 1px solid black;
    padding: 5px;
    margin-bottom: -6px;
}

.matchList__date-text,
.matchList__closingDate-text {
    font-size: 10px;
    letter-spacing: 1px;
}

.matchList__date-text {
    text-decoration: underline;
    text-underline-offset: 10px;
    text-decoration-thickness: 2px;
    text-decoration-color: #d8d9db;
}

.matchList__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 35px;
    margin-bottom: 20px;
}

.matchList__header__title-matchcount {
    font-family: 'NEXA X BOLD';
}

.matchList__header__title {
    display: inline-block;
    font-size: 18px;
    margin: 0;
}

.matchList__header__matchcount {
    display: inline-block;
    border-radius: 100%;
    margin-left: 10px;
    width: 35px;
    height: 35px;
    background: linear-gradient(180deg, white, #c8c9cb);
    border-radius: 100%;
    text-align: center;
    color: var(--light-color);
}

.matchList__team-header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 15px;
}

.matchList__team-section {
    width: 100%;
    text-align: center;
}

.matchList__team-info {
    display: block;
    width: 100%;
    text-align: center;
}

.matchList__team-info .statscontainer {
    cursor: pointer;
}

.matchList__team-info__spanstats {
    font-size: 12px;
    font-family: 'NEXA BOLD';
}

.matchList__team-info__iconstats {
    font-size: 12px;
    margin-left: 5px;
}

.matchList__team-icon {
    height: 40px;
}

.matchList__team-name {
    font-size: 10px;
    margin-top: 0;
}

.matchList__match-text {
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: var(--btn-color);
}

.matchList__match-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.matchList__btn_match-option {
    position: relative;
    flex: 1 0 calc(33.3% - 10px);
    min-height: 50px;
    box-sizing: border-box;
    margin: 5px;
    padding: 5px;
    color: var(--main-color);
    background: linear-gradient(180deg, var(--white-color), #c8c9cb);
    text-transform: uppercase;
    font-family: 'NEXA X BOLD';
    border: 0px;
    border-radius: 10px;
    cursor: pointer;
}

.matchList__btn_match-option--disabled:after {
    cursor: default;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: -webkit-linear-gradient(320deg, #5cb85c 25%, transparent 0, transparent 50%, #5cb85c 0, #5cb85c 75%, transparent 0, transparent);
    background-image: -moz-linear-gradient(320deg, #5cb85c 25%, transparent 25%, transparent 50%, #5cb85c 50%, #5cb85c 75%, transparent 75%, transparent 100%);
    background-image: -o-linear-gradient(320deg, #5cb85c 25%, transparent 25%, transparent 50%, #5cb85c 50%, #5cb85c 75%, transparent 75%, transparent 100%);
    background-image: linear-gradient(130deg, #5cb85c 25%, transparent 0, transparent 50%, #5cb85c 0, #5cb85c 75%, transparent 0, transparent);
    background-size: 6.22px 8.22px;
    opacity: .4;
    z-index: 20;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
}

.matchList__btn_active,
.matchList__btn_match-option:active {
    background: var(--btn-color);
}

.matchList__btn_match-option--disabled:hover,
.matchList__btn_match-option--disabled:active {
    background: linear-gradient(180deg, var(--white-color), #c8c9cb);
}

.matchList__btn_match-option--lock {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 38%;
    left: 10%;
}

.matchList__matches-seperator,
.matchlistnovisible,
.statsvisible-no {
    display: none;
}

.statisticsvisible {
    display: block;
    width: 100%;
}

.matchList__promo_section {
    margin-right: 20px;
    width: 30%;
    z-index: 100;
}

.promo_banner {
    margin-top: 20px;
    width: 100%;
}

@media (min-width: 768px) {
    .matchList__btn_match-option:hover {
        background: var(--btn-color);
    }
}

@media (max-width: 991px) {

    .matchList__header {
        flex-direction: column;
        align-items: flex-start;
    }

    .matchList__btn_match-option {
        font-size: 12px;
    }

    .matchList__matches-seperator {
        display: block;
        background-color: var(--main-color);
        height: 2px;
        margin: 20px -50px 0;
    }

    .matchList__matches-seperator:last-of-type {
        display: none;
    }

    .matchList__team-icon {
        display: none;
    }

    .matchList__promo_section {
        width: 0;
    }
}

@media (max-width: 767px) {

    .matchList__btn_match-option--lock {
        position: absolute;
        width: 10px;
        height: 10px;
        top: 10%;
        left: 45%;
    }

    .matchList__start-date {
        display: block;
        width: 100%;
    }

    .matchList__date-text {
        display: block;
    }

    .savedbet {
        margin-top: 20px;
    }
}

@media (max-width: 366px) {
    .matchList__btn_match-option {
        font-size: 10px;
    }
}