.pool-container {
	padding: 15px 0;
}

.pool-container__title {
	display: flex;
	justify-content: space-between;
}

.pool-container__titleCount {
	display: flex;
	margin: 10px 0;
	justify-content: space-between;
}

.pool-container-hide {
	display: none;
}

.pool-container__title-pooltitle,
.pool-container__title-matchcount__count,
.pool-container__block-desc,
.pool-container__block-price {
	text-transform: uppercase;
	font-family: "NEXA X BOLD";
}

.pool-container__title-pooltitle {
	font-size: 16px;
	line-height: 30px;
	color: white;
}

.pool-container__title-matchcount {
	width: fit-content;
	margin-left: 10px;
	width: 30px;
	height: 30px;
	background: linear-gradient(180deg, white, #c8c9cb);
	border-radius: 100%;
	text-align: center;
}

.pool-container__title-matchcount__count {
	line-height: 28px;
	color: var(--light-color);
}

.pool-container__link {
	text-decoration: none;
}

.pool-container__block {
	height: fit-content;
	border-radius: 50px;
	background: linear-gradient(180deg, white, #c8c9cb);
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 5px 20px;
	cursor: pointer;
}

.pool-container__block-desc,
.pool-container__block-price {
	color: var(--main-color);
}

.pool-container__block-stake {
	text-align: end;
}

.pool-container__block-desc__mess,
.pool-container__block-desc__date {
	display: block;
	width: 100%;
}

.pool-container__block-desc__mess {
	font-size: 14px;
}

.pool-container__block-desc__date {
	text-transform: uppercase;
	font-size: 10px;
	color: var(--light-color);
}

.pool-container__block-stake__amount {
	display: block;
	text-transform: uppercase;
	font-size: 9px;
	color: var(--light-color);
}

.pool-container__block-price {
	margin-right: 10px;
	font-size: 16px;
}

.pool-container__block-price:after {
	content: "";
	border-right: 2px solid #bc0404;
	border-bottom: 2px solid #bc0404;
	position: absolute;
	margin: 5px;
	width: 8px;
	height: 8px;
	transform: rotate(-45deg);
}

.pool-container__pool-info {
	display: flex;
	align-items: flex-start;
	font-size: 12px;
}

.pool-container__pool-stats {
	position: relative;
	display: flex;
	flex-direction: column;
	margin: 10px auto 0 0;
	width: fit-content;
	border-radius: 20px;
	text-transform: uppercase;
	color: var(--main-color);
	background-color: #efb014;
	padding: 5px 15px;
}

.pool-container__pool-stats::before {
	position: absolute;
	top: -5px;
	left: 100px;
	content: '';
	width: 10px;
	height: 10px;
	background-color: #efb014;
	transform: rotate(45deg);
}

.pool-container__total-pool-amount {
	margin: 10px 0 0 auto;
	width: fit-content;
	border-radius: 20px;
	text-transform: uppercase;
	color: var(--main-color);
	background-color: #efb014;
	justify-content: space-between;
	padding: 10px 15px;
}

@media only screen and (max-width: 767px) {

	.pool-container__block-price {
		margin-right: 5px;
		font-size: 12px;
	}

	.pool-container__block-price:after {
		margin: 3px;
		width: 7px;
		height: 7px;
	}

	.pool-container__pool-info {
		margin-top: 10px;
		font-size: 10px;
		border-radius: 20px;
		background-color: #efb014;
	}

	.pool-container__pool-stats {
		background-color: transparent;
		margin: 0 auto 0 0;
	}

	.pool-container__total-pool-amount {
		background-color: transparent;
		margin: 0 0 0 auto;
		padding: 5px 10px;
	}
}