.statistics-sections {
	width: fit-content;
	margin: 30px auto 0 auto;
}

.statistcswrapper {
	width: 100%;
}

.statistics-section__title,
.teamlogo-name {
	text-transform: uppercase;
	color: #fec83f;
	font-size: 12px;
	font-family: "NEXA BOLD";
}

.btns-statistics-wrapper {
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
}

.btns-statistics-wrapper-team {
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 60px;
	width: 30%;
	border-radius: 10px;
	background: linear-gradient(180deg, white, #c8c9cb);
	padding: 5px 15px;
	cursor: pointer;
}

.btns-statistics-wrapper-team__image {
	height: 45px;
	margin: 0;
}

.btns-statistics-wrapper-team__name {
	text-transform: uppercase;
	color: var(--main-color);
	font-size: 12px;
	font-family: "NEXA BOLD";
	width: fit-content;
	margin: auto;
	text-align: center;
	word-break: break-word;
}

.showdata {
	display: none;
}

.teamblock {
	text-align: center;
}

.teamlogo-name {
	display: block;
	word-break: break-word;
	font-size: 14px;
}

.activebtnchoose {
	background: #fec83f;
}

.homeaway-container {
	background-color: var(--main-color);
	/* padding: 10px; */
	width: 25%;
	border-radius: 15px;
}

.homeaway-container.info {
	background-color: transparent;
	text-align: center;
}

.homeaway-container-winstate {
	width: 100%;
	text-align: center;
	margin: 10px 0;
}

.homeaway-container-winstate__text {
	color: white;
	font-size: 14px;
	font-family: "NEXA BOLD";
	letter-spacing: 2px;
}

.matchesinfo-container {
	margin-top: 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.match-preview {
	width: 100%;
	display: block;
	text-align: center;
	margin: 30px 0;
}

.match-preview-wrapper__string {
	width: fit-content;
	margin: auto;
	color: white;
	font-family: "NEXA BOLD";
	text-transform: uppercase;
	font-size: 14px;
}

.match-preview-wrapper__string.match-string {
	color: #fec83f;
}

.match-preview-wrapper.match-string-space {
	margin-top: 20px;
}

.team-photo-preview {
	height: 90px;
}

@media only screen and (max-width: 767px) {
	.homeaway-container {
		padding: 5px;
		width: 30%;
	}

	.teamlogo-name {
		width: 70%;
		margin: 0 auto;
		font-size: 11px;
	}
}

@media only screen and (max-width: 991px) {
	.btns-statistics-wrapper-team__image {
		display: none;
	}

	.teamname {
		margin: 0 auto;
	}

	.btns-statistics-wrapper-team__name {
		font-size: 12px;
	}

	.btns-statistics-wrapper-team {
		justify-content: center;
		padding: 5px 4px;
	}

	.homeaway-container-winstate__text,
	.match-preview-wrapper__string {
		font-size: 11px;
	}

	.homeaway-container-winstate {
		margin: 5px 0;
	}

	.homeaway-container.info {
		padding: 5px;
	}

	.team-photo-preview {
		height: 50px;
	}
}