.detailspool-container {
	width: 95%;
	background: linear-gradient(180deg, white, #c8c9cb);
	height: 40px;
	border-radius: 50px;
	margin: 5px auto;
	padding: 10px 0;
	overflow: hidden;
	cursor: pointer;
}

.showinfo {
	height: fit-content;
	border-radius: 15px;
}

.detailspool-container__date {
	text-align: center;
	font-size: 14px;
	color: var(--main-color);
	font-family: "NEXA X BOLD";
	text-transform: uppercase;
}

.detailspool-container__arrow {
	text-align: right;
	margin-right: 40px;
	margin-top: -10px;
}

.detailspool-container__arrow:after {
	content: "";
	position: absolute;
	border-right: 2px solid var(--main-color);
	border-bottom: 2px solid var(--main-color);
	width: 8px;
	height: 8px;
	transform: rotate(45deg);
}

.detailspool-container__pool-stats {
	position: relative;
	display: flex;
	flex-direction: column;
	margin: 10px auto;
	width: fit-content;
	font-size: 12px;
	border-radius: 20px;
	text-transform: uppercase;
	color: var(--main-color);
	background-color: #efb014;
	padding: 5px 15px;
}

.detailspool-container__pool-stats::before {
	position: absolute;
	top: -5px;
	left: calc(50% - 5px);
	content: '';
	width: 10px;
	height: 10px;
	background-color: #efb014;
	transform: rotate(45deg);
}

.openarrowdown:after {
	transform: rotate(225deg);
}

.details-header {
	background-color: #29483a;
	margin-top: 25px;
	display: flex;
	justify-content: space-around;
	text-transform: uppercase;
	font-family: "NEXA X BOLD";
	color: white;
	font-size: 12px;
	padding: 10px 0;
}

@media only screen and (max-width: 767px) {
	.detailspool-container__date {
		font-size: 12px;
		line-height: 25px;
	}

	.detailspool-container__arrow:after {
		width: 6px;
		height: 6px;
	}

	.detailspool-container__arrow {
		margin-top: -20px;
		margin-right: 25px;
	}

	.detailspool-container__pool-stats {
		font-size: 10px;
	}
}